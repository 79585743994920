<template>
	<div class="d-flex flex-column aqipointsmappanel fill-height max-height-100">
		<div class="d-flex flex-column py-3 px-3 bottomborder" ref="header">
			<div class="aqi-points-title">{{ $t('panels.aqipointspanel.title') }}</div>
			<div class="aqi-points-subtitle fw-regular fs-sm">{{ $t('panels.aqipointspanel.subtitle') }}</div>
		</div>

		<div class="lists pb-0" style="min-height: 30%">
			<v-list flat dense style="background: transparent" class="pt-0">
				<!-- Aqi Points -->
				<div v-if="showAqiPoints">
					<v-list-item class="pl-1 pr-3">
						<aqi-points-list ref="aqipointslist" />
					</v-list-item>
				</div>

				<v-divider style="height: 1px; background: rgba(0, 0, 0, 0.05)" :class="{ 'mt-2': isOpened.aqipoints }" />
			</v-list>
		</div>
	</div>
</template>

<script>
import AqiPointsList from './AqiPointsList';

export default {
	name: 'aqipointsmappanel',
	data() {
		return {
			showAqiPoints: false
		};
	},
	components: {
		AqiPointsList
	},

	computed: {
		isOpened() {
			return this.$store.state.aqiPointsPanel.isOpened;
		}
	},

	created() {
		this.showAqiPoints = this.$store.getters.getHasAqiPoints;
		console.log('show aqi points: ' + this.showAqiPoints);
		console.log(this.$store.getters.getHasAqiPoints);
	},

	methods: {}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';
.aqipointsmappanel {
	& .list-icon {
		color: var(--azul-primary-100);

		&--opened {
			transform: rotateZ(-90deg) !important;
		}
	}

	& .lists {
		overflow-x: hidden;
		overflow-y: auto;

		& .maintitle {
			font-weight: 700;
			font-size: 1.1rem;
			font-weight: bold;
			color: #000;
		}
	}

	& .aqi-points-title {
		font-size: 1.3rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #000;
		margin-bottom: 2px;
	}

	& .aqi-points-subtitle {
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: var(--gris-500);
		margin-top: 2px !important;
	}
}
.bottomborder {
	border-bottom: 1px solid var(--moderate);
}
</style>
