<template>
	<div class="aqipointslist d-flex flex-column pa-0" style="width: 100%">
		<div class="mt-1 subheader" v-if="isAqiPoints">
			<!-- Selection controls: All & Nothing -->
			<!-- <span class="selector" @click="changeAll(true)">{{ $t('panels.configpannel.all') }}</span>
			<span class="ml-2 mr-2">|</span>
			<span class="selector" @click="changeAll(false)">{{ $t('panels.configpannel.none') }}</span> -->
		</div>
		<div v-if="!isSuperAdmin">
			<!-- Layer List -->
			<v-list dense style="background: transparent !important" class="aqipoint-list py-0">
				<div v-for="(aqipoint, i) in aqipoints" :key="i">
					<aqi-point-list-title
						:value="true"
						:active="isActive(aqipoint.identifier)"
						@update:active="(value) => setActive(aqipoint.identifier, value)"
						:title="aqipoint.name"
						:disabledItem="aqipoint.disabledItem"
						:noPadding="true"
						border-bottom
					>
					</aqi-point-list-title>
				</div>
			</v-list>
		</div>
		<div v-else>
			<!-- Layer List by Organization-->
			<div v-for="(item, i) in aqiPointsByOrg" :key="i">
				<v-list flat dense style="background: transparent" class="pt-0">
					<v-list-group>
						<template #activator>
							<v-list-item-title class="high fw-bold fs-regular ml-n2">{{ item.organizationname }}</v-list-item-title>
						</template>

						<div v-for="(aqipoint, i) in item.aqiPointGroup" :key="i">
							<aqi-point-list-title
								:value="true"
								:active="isActive(aqipoint.identifier)"
								@update:active="(value) => setActive(aqipoint.identifier, value)"
								:title="aqipoint.name"
								:disabledItem="aqipoint.disabledItem"
								:noPadding="true"
								border-bottom
							>
							</aqi-point-list-title>
						</div>
					</v-list-group>
				</v-list>
			</div>
		</div>
	</div>
</template>

<script>
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import AqiPointListTitle from './subpanels/common/AqiPointListTitle';
import { isSuperAdmin } from '@/api/common';

export default {
	name: 'aqipointssection',

	data() {
		return {
			allAqiPoints: [],
			//satelliteImagesGroupedByType: [],
			aqiPointsByOrg: [],
			profile: '',
			isSuperAdmin: false
		};
	},

	components: {
		AqiPointListTitle
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		console.log('SUPER_ADMIN_ROLE?: ' + this.isSuperAdmin);
		if (this.isSuperAdmin) {
			this.afterGetData();
		}
		console.log('== AqiPoints getter ==');
		console.log(this.$store.getters.getAqiPoints);
	},
	/*created() {

		this.profile = session.profile;
		console.log('USER PROFILE: ', this.profile);
		console.log(this.isSuperAdmin);
		if (this.isSuperAdmin) {
			this.afterGetData();
		}
	},*/

	computed: {
		isAqiPoints() {
			return Object.keys(this.$store.getters.getAqiPoints).length > 0;
		},

		aqipoints() {
			return this.$store.getters.getAqiPoints;
		}
	},

	methods: {
		isActive(aqiPointId) {
			return this.$store.state.mappanel.aqipoints[aqiPointId]['active'];
		},

		toggleAll(aqipoint, value) {
			ol2map.setVisibility(aqipoint, value);
			this.$store.state.mappanel.aqipoints[aqipoint]['active'] = value;
		},

		setActive(aqipoint, active) {
			ol2map.setVisibility(aqipoint, active);
			this.$store.state.mappanel.aqipoints[aqipoint]['active'] = active;
		},
		async afterGetData() {
			this.allAqiPoints = Object.entries(this.aqipoints).map(([name, obj]) => ({ name, ...obj }));
			console.log('-- AqiPoints retrieved --');
			console.log(this.aqipoints);
			this.aqiPointsByOrg = this.groupByOrganization(this.allAqiPoints);
			console.log('AqiPoints grouped by organization');
			console.log(this.aqiPointsByOrg);
		},

		groupByOrganization(dataset) {
			console.log('DATASET AQIPOINTS');
			console.log(dataset);
			const object = dataset.reduce((r, { organizationname, ...rest }) => {
				if (!r[organizationname]) r[organizationname] = { organizationname, aqiPointGroup: [rest] };
				else r[organizationname].aqiPointGroup.push(rest);
				return r;
			}, {});

			const result = Object.values(object);
			return result;
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';
.aqipoint-list {
	& .subheader {
		font-size: 10px;
		color: var(--azul-primary-100);

		& .selector {
			color: var(--azul-primary-100);
			font-size: 0.9rem;
			line-height: 1.33;

			&:hover {
				cursor: pointer;
				font-weight: 900;
			}
		}
	}

	& .bottomborder {
		border-bottom: 1px solid var(--moderate);
	}
}
</style>
